import axios from "axios";
import { Response } from "@/types";
import { Message, MessageBox } from "element-ui";
import * as storage from "@/utils";
import * as isLiu from "@/utils/judgeEnv";
import router from "../router";

import qs from "qs";

const ins = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false,
  // headers: { "Content-Type": "application/json; charset=UTF-8; image/jpeg" }, //"Accept": "*/*", "Accept-Encoding": "gzip,deflate,br", "Connection": "keep-alive" },
});

ins.interceptors.request.use((config) => {
  console.log("Authorization=request.ts");
  if (storage.get("auth")) {
    config.headers["Authorization"] = storage.get("auth");
  }
  if (storage.get("REQUEST-AUTH")) {
    config.headers["REQUEST-AUTH"] = storage.get("REQUEST-AUTH");
  }
  return config;
});

ins.interceptors.response.use(
  (res) => {
    const data = res.data as Response;
    if (data.success) {
      // Message({
      //   message: "请求成功啦",
      //   type: "success",
      //   duration: 5 * 1000,
      // });
    } else {
      const errorLevel = res.data.error_level;
      if (errorLevel === 1) {
        Message({
          message: data.message,
          type: "error",
        });
      } else if (errorLevel === 2) {
        const errorCode = res.data.error_code;
        if (errorCode === 501) {
          Message({
            message: "token失效，请重新登录",
            type: "error",
          });
          storage.set("auth", null);
          // location.reload();
          if (isLiu.judgeEnv()) {
            router.push("/login_liu");
          } else {
            router.push("/login");
          }
        } else {
          const errorMessage = res.data.message
            ? res.data.errorMessage
            : "未知错误";
          Message({
            message: errorMessage,
            type: "error",
          });
          // console.log("error:", data);
        }
      }
    }
    return res.data;
  },
  (error) => {
    if (error.code === "ECONNABORTED") {
      return;
    }
    return Promise.reject(error);
  }
);

export default ins;

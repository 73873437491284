import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Layout from "@/views/Layout/index.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
        meta: { title: "首页", icon: "shouye" },
      },
    ],
  },
  {
    path: "/home_liu",
    component: Layout,
    redirect: "/home_liu",
    meta: { title: "首页", icon: "shouye" },
    children: [
      {
        path: "/home_liu",
        name: "HomeLiu",
        component: () => import("@/views/Home/liu.vue"),
        meta: { title: "首页", icon: "shouye" },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/login_liu",
    name: "LoginLiu",
    component: () => import("@/views/LoginLiu.vue"),
  }, //ActionCenter
  {
    path: "/direct_link",
    name: "DirectLink",
    component: () => import("@/views/DirectLink.vue"),
  },
  {
    path: "/directLink",
    name: "DirectLink",
    component: () => import("@/views/DirectLinkSet.vue"),
  },
  //一级目录-电子病例库
  {
    path: "/elemedirecord",
    meta: { title: "电子病历库", icon: "shouye" },
    redirect: "/doctor-system/medical/patients",
    component: Layout,
    children: [
      ///doctor-system/personal/info
      {
        path: "/elemedirecord/patientdata",
        name: "patientdata",
        component: () => import("@/views/EleMediRecord/PatientData/index.vue"),
        meta: {
          title: "患者资料",
          icon: "shouye",
          pageIndex: 1,
        },
      },
      {
        path: "/elemedirecord/patientdata/detail",
        name: "patientdata",
        component: () => import("@/views/EleMediRecord/PatientData/detail.vue"),
        meta: {
          // title: "浏览患者>患者详情",
          title: "患者详情",
          icon: "shouye",
          pageIndex: 2,
        },
      },
      {
        path: "/doctor-system/medical/patients",
        name: "patientview",
        component: () => import("@/views/EleMediRecord/PatientView/index.vue"),
        meta: {
          title: "电子病历库 / 浏览患者",
          icon: "shouye",
          pageIndex: 1,
        },
      },
      {
        path: "/doctor-system/medical/patients/detail",
        name: "viewDetail",
        component: () => import("@/views/EleMediRecord/PatientView/Detail.vue"),
        meta: {
          title: "患者详情",
          icon: "shouye",
          pageIndex: 2,
          prevRouter: {
            path: "/doctor-system/medical/patients",
            meta: {
              title: "浏览患者",
              pageIndex: 1,
            },
          },
        },
      },
      //嘉医工作站一期-分组维护列表
      {
        path: "/doctor-system/medical/tags", //labelManage
        name: "labelmanage",
        component: () => import("@/views/EleMediRecord/labelManage.vue"),
        meta: {
          title: "分组设置",
          icon: "shouye",
          pageIndex: 1,
        },
      },
      //嘉医工作站二期-量表评估列表页
      {
        path: "/doctor-system/elemedirecord/scaleAssessment",
        name: "scaleAssessment",
        component: () =>
          import("@/views/EleMediRecord/scaleAssessment/index.vue"),
        meta: {
          title: "量表评估",
          icon: "shouye",
          pageIndex: 1,
        },
      },

      //嘉医工作站二期-量表新增页
      {
        path: "/doctor-system/elemedirecord/scaleAssessment/add",
        name: "scaleAssessmentAdd",
        component: () =>
          import("@/views/EleMediRecord/scaleAssessment/add.vue"),
        meta: {
          title: "量表评估新增",
          icon: "shouye",
          pageIndex: 1,
        },
      },
      //嘉医工作站二期-量表查看页
      {
        path: "/doctor-system/elemedirecord/scaleAssessment/view",
        name: "scaleAssessmentView",
        component: () =>
          import("@/views/EleMediRecord/scaleAssessment/view.vue"),
        meta: {
          title: "量表评估详情",
          icon: "shouye",
          pageIndex: 1,
        },
      },
      //嘉医工作站三期-程控历史列表页
      {
        path: "/doctor-system/elemedirecord/programControlHistory",
        name: "programControlHistory",
        component: () =>
          import("@/views/EleMediRecord/programControlHistory/index.vue"),
        meta: {
          title: "程控历史",
          icon: "shouye",
          pageIndex: 1,
        },
      },
      {
        path: "/doctor-system/medical/patients/detail/tags", //labelManage
        name: "labelmanage",
        component: () => import("@/views/EleMediRecord/LabelMaintain.vue"),
        meta: {
          title: "标签设置",
          icon: "shouye",
          pageIndex: 3,
          prevRouter: {
            path: "/doctor-system/medical/patients/detail",
            meta: {
              title: "患者详情",
              icon: "shouye",
              toList: true,
              pageIndex: 2,
            },
          },
        },
      },
      {
        path: "/elemedirecord/medrecadmin",
        name: "medrecadmin",
        component: () => import("@/views/EleMediRecord/MedRecAdmin.vue"),
        meta: {
          title: "病历管理",
          icon: "shouye",
          permission: "/elemedirecord/medrecadmin.vue",
        },
      },
      {
        path: "/elemedirecord/newadd",
        name: "newadd",
        component: () => import("@/views/EleMediRecord/NewAdd.vue"),
        meta: {
          title: "新增患者",
          icon: "shouye",
          permission: "/elemedirecord/NewAdd.vue",
        },
      },
      //嘉医工作站一期-病例列表页
      {
        path: "/doctor-system/medical/list",
        name: "patientCase",
        component: () => import("@/views/EleMediRecord/Scale/Index.vue"),
        meta: {
          title: "电子病历库 / 患者病历",
          icon: "shouye",
          pageIndex: 1,
          children: [],
        },
      },
      //嘉医工作站一期-病例新增
      {
        path: "/doctor-system/medical/add",
        name: "addPatientCase",
        component: () => import("../views/EleMediRecord/Scale/AddCases.vue"),
        meta: {
          title: "新增",
          icon: "shouye",
          pageIndex: 2,
          prevRouter: {
            path: "/doctor-system/medical/list",
            meta: {
              title: "新增病历",
              pageIndex: 1,
            },
          },
        },
      },
      //嘉医工作站一期-病例详情主页面
      {
        path: "/doctor-system/medical/view",
        name: "viewPatientCase",
        component: () =>
          import("@/views/EleMediRecord/Scale/Details/index.vue"),
        meta: {
          title: "查看",
          icon: "shouye",
          pageIndex: 2,
          prevRouter: {
            path: "/doctor-system/medical/list",
            meta: {
              title: "患者病历",
              pageIndex: 1,
            },
          },
        },
      },
      //原来的量表评估
      {
        path: "/doctor-system/medical/evaluation/list",
        name: "evaluationlist",
        component: () =>
          import("@/views/EleMediRecord/ScaleEvaluation/index.vue"),
        meta: {
          title: "量表评估",
          icon: "shouye",
          pageIndex: 2,
          prevRouter: {
            path: "/doctor-system/medical/list",
            meta: {
              title: "患者病历",
              pageIndex: 1,
            },
          },
        },
      },
      //原来量表评估-添加页
      {
        path: "/doctor-system/medical/evaluation/add",
        name: "evaluationadd",
        component: () =>
          import("@/views/EleMediRecord/ScaleEvaluation/add.vue"),
        meta: {
          title: "添加量表",
          pageIndex: 3,
          prevRouter: {
            path: "/doctor-system/medical/evaluation/list",
            meta: {
              title: "量表评估",
              icon: "shouye",
              pageIndex: 2,
            },
          },
        },
      },
      //原来的量表评估-详情页
      {
        path: "/doctor-system/medical/evaluation/detail",
        name: "evaluationdetail",
        component: () =>
          import("@/views/EleMediRecord/ScaleEvaluation/detail.vue"),
        meta: {
          title: "量表详情",
          pageIndex: 3,
          prevRouter: {
            path: "/doctor-system/medical/evaluation/list",
            meta: {
              title: "量表评估",
              icon: "shouye",
              pageIndex: 2,
            },
          },
        },
      },
    ],
  },
  {
    path: "/onlineconsultroom",
    meta: { title: "个人管理", icon: "shouye" },
    redirect: "/doctor-system/personal/info",
    component: Layout,
    children: [
      {
        path: "/doctor-system/personal/info",
        name: "personal",
        component: () => import("@/views/PersonalManage/index.vue"),
        meta: {
          title: "个人管理  / 我的信息",
          icon: "shouye",
          permission: "/PersonalManage",
          pageIndex: 1,
        },
      },
    ],
  },
  {
    path: "/reset",
    name: "ResetPassword",
    component: Layout,
    children: [
      {
        path: "password",
        component: () => import("@/views/ResetPassword.vue"),
        name: "Home",
        meta: { title: "修改密码", icon: "shouye" },
      },
    ],
  },
  // {
  //   path: "/",
  //   meta: { title: "操作日志", icon: "shouye" },
  //   redirect: "/system/maintain/worklog",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/system/maintain/worklog",
  //       name: "perationsTimingScheduleOperationLog",
  //       component: () =>
  //         import("@/views/Operations/TimingSchedule/operationLog.vue"),
  //       meta: {
  //         title: "操作日志",
  //         icon: "shouye",
  //         pageIndex: 1,
  //       },
  //     },
  //   ],
  // },
  //一级目录-在线诊室
  {
    path: "/doctor-system/online/patient",
    meta: { title: "在线诊室", icon: "shouye" },
    redirect: "/onlineconsultroom/inquirylist",
    component: Layout,
    children: [
      {
        //IncomeDetail
        path: "/doctor-system/online/order/inquiry",
        name: "InquiryList",
        component: () =>
          import("@/views/OnlineConsultRoom/InquiryList/index.vue"),
        meta: {
          title: "问诊订单",
          icon: "shouye",
          permission: "/onlineconsultroom/inquirylist",
          pageIndex: 1,
        },
      },
      {
        path: "/doctor-system/online/income",
        name: "IncomeDetail",
        component: () =>
          import("@/views/OnlineConsultRoom/IncomeDetail/index.vue"),
        meta: {
          title: "收入明细",
          icon: "shouye",
          permission: "/onlineconsultroom/incomedetail",
          pageIndex: 1,
        },
      },
      {
        path: "/doctor-system/online/income/record",
        name: "record",
        component: () =>
          import("@/views/OnlineConsultRoom/IncomeDetail/Checked.vue"),
        meta: {
          title: "打款记录",
          icon: "shouye",
          permission: "/onlineconsultroom/incomedetail",
          pageIndex: 1,
        },
      },
      {
        path: "/doctor-system/online/order/appointment",
        name: "AppointmentList",
        component: () =>
          import("@/views/OnlineConsultRoom/AppointmentList/index.vue"),
        meta: {
          title: "程控订单",
          icon: "shouye",
          permission: "/onlineonsultroom/appointmentList",
          pageIndex: 1,
        },
      },
      {
        path: "/doctor-system/online/patient",
        name: "MyPatient",
        component: () =>
          import("@/views/OnlineConsultRoom/MyPatient/index.vue"),
        meta: {
          title: "我的患者",
          icon: "shouye",
          permission: "/onlineconsultroom/mypatient",
          pageIndex: 1,
        },
      },
      {
        path: "/onlineconsultroom/patiented",
        name: "patiented",
        component: () =>
          import("@/views/OnlineConsultRoom/Patiented/index.vue"),
        meta: {
          title: "诊后患者",
          icon: "shouye",
          permission: "/onlineconsultroom/patiented",
          pageIndex: 1,
        },
      },
      {
        path: "/onlineconsultroom/mypatient/detail",
        name: "MyPatientDetail",
        component: () =>
          import("@/views/OnlineConsultRoom/PatientDetail/index.vue"),
        meta: {
          title: "患者详情",
          icon: "shouye",
          pageIndex: 2,
          permission: "/onlineconsultroom/mypatient",
          prevRouter: {
            path: "/doctor-system/online/patient",
            meta: {
              title: "我的患者",
              pageIndex: 1,
            },
          },
        },
      },
    ],
  }, //OnlineConsultRoom
  //直播患者;
  {
    path: "/livePatient",
    meta: { title: "直播患者", icon: "shouye" },
    redirect: "/livePatient/index",
    component: Layout,
    children: [
      {
        path: "/livePatient/index",
        name: "livePatientIndex",
        component: () => import("@/views/LivePatient/index.vue"),
        meta: { title: "直播患者", icon: "shouye" },
      },
      //直播患者详情;
      {
        path: "/livePatient/detail",
        name: "livePatientDetail",
        component: () => import("@/views/LivePatient/detail.vue"),
        meta: { title: "详情", icon: "shouye", pageIndex: 1 },
      },
    ],
  },
  //直播问题;
  {
    path: "/liveQuestion",
    meta: { title: "直播患者", icon: "shouye" },
    redirect: "/liveQuestion/index",
    component: Layout,
    children: [
      {
        path: "/liveQuestion/index",
        name: "liveQuestionIndex",
        component: () => import("@/views/LiveQuestion/index.vue"),
        meta: { title: "直播问题", icon: "shouye" },
      },
      {
        path: "/liveQuestion/detail",
        name: "liveQuestionDetail",
        component: () => import("@/views/LiveQuestion/detail.vue"),
        meta: { title: "详情", icon: "shouye", pageIndex: 1 },
      },
    ],
  },
  // 医生内容页;
  {
    path: "/doctor/content",
    meta: { title: "医生内容", icon: "shouye" },
    redirect: "/doctor/content",
    component: Layout,
    children: [
      {
        path: "/doctor/content",
        name: "DoctorContent",
        component: () => import("@/views/DoctorMoreContent/index.vue"),
        meta: { title: "医生内容", icon: "shouye" },
      },
      {
        path: "/doctor/add",
        name: "DoctorContentAdd",
        component: () => import("@/views/DoctorMoreContent/add.vue"),
        meta: { title: "医生内容新增", icon: "shouye" },
      },
      {
        path: "/doctor/edit",
        name: "DoctorContentAdd",
        component: () => import("@/views/DoctorMoreContent/edit.vue"),
        meta: { title: "医生内容编辑", icon: "shouye" },
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err);
};

router.beforeEach((from, to, next) => {
  next();
});

export default router;

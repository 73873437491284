












import { Component, Vue } from "vue-property-decorator";
import SideBar from "./SideBar.vue";
import NavBar from "./NavBar.vue";

@Component({
  components: { SideBar, NavBar },
})
export default class Layout extends Vue {
  mounted() {
    window.vm = this;
  }
  //需要缓存的页面名
  cacheList = ["PatientCase", "scaleAssessmentList"];
}

import api from "../request";
import { Response } from "@/types";

export const ApiDoctorName = (params?: any): Promise<Response> =>
  api.post(`/doctor/get-info`, params);

export const ApiDoctorArticle = (params?: any): Promise<Response> =>
  api.post(`/doctor/article-list`, params);

export const ApiDoctorArticleAdd = (params?: any): Promise<Response> =>
  api.post(`/doctor/article/add`, params);

export const ApiDoctorArticleUpdate = (params?: any): Promise<Response> =>
  api.post(`/doctor/article/update`, params);

export const ApiDoctorArticleDel = (id?: any): Promise<Response> =>
  api.post(`/doctor/article-delete/${id}`, {});

export const ApiDoctorArticleUnpublished = (id?: any): Promise<Response> =>
  api.post(`/doctor/article-unpublished/${id}`, {});

export const ApiDoctorArticlePublished = (id?: any): Promise<Response> =>
  api.post(`/doctor/article-publish/${id}`, {});

export const ApiDoctorList = (params: any): Promise<Response> =>
  api.post(`/account/doctor/list`, params);

import api from "../request";
import { Response } from "@/types";

//电子病例库-列表
interface ApiElectronicCaseParam {
  user_name: string | number;
  page_size: string | number;
}
export const ApiElectronicCaseList = (
  params?: ApiElectronicCaseParam
): Promise<Response> => api.post(`/patient-record/list`, params);

//电子病例-保存-基础信息
export const ApiBasicInformationSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/basic-information/add`, params);
export const ApiBasicInfoCheckName = (params?: any): Promise<Response> =>
  api.post(`/el-record/base-info/check-name`, params);

export const ApiBasicInformationDel = (params?: any): Promise<Response> =>
  api.post(`/case-history/base-info/delete`, params);

//电子病例-下拉框列表-所患疾病
export const ApiGetllnessList = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/userDisease/list`, params);
//电子病例-级联选择器-省份
export const ApiGetProvince = (params?: any): Promise<Response> =>
  api.post(`/address/province/list`, params);
//电子病例-级联选择器-城市
export const ApiGetCity = (params?: any): Promise<Response> =>
  api.post(`/address/city/list`, params);
//电子病例-级联选择器-区县
export const ApiGetArea = (params?: any): Promise<Response> =>
  api.post(`/address/area/list`, params);
//电子病例-保存-主诉
export const ApiChiefComplaintSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/medical-trmtchief/add`, params);
//电子病例-保存-起病情况-onset
export const ApiOnsetSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/medical-status/add`, params);
//电子病例-保存-进展顺序-progressionOrder
export const ApiProgressionOrderSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/medical-order/add`, params);
//电子病例-保存-主要症状-mainSymptoms
export const ApiMainSymptomsSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/medical-cardinalSymptom/add`, params);
//电子病例-保存-其他运动症状-otherMotorSymptoms
export const ApiOtherMotorSymptomsSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/medical-otherSport/add`, params);
//电子病例-保存-症状诊断-symptomDiagnosis
export const ApiSymptomDiagnosisSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/medical-chatidiag/add`, params);
//电子病例-保存-过敏史-allergyHistory
export const ApiAllergyHistorySave = (params?: any): Promise<Response> =>
  api.post(`/el-record/allergies/addOrEdit`, params);
//电子病例-保存-既往疾病史-pastMedicalHistory
export const ApiPastMedicalHistorySave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/jiwangjibing/add`, params);
//电子病例-保存-既往环境接触史-environmentalExposure
export const ApiEnvironmentalExposureSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/environmental-contact/add`, params);
export const ApiEnvironmentalExposureSavePsp = (
  params?: any
): Promise<Response> => api.post(`/case-history/environment/add`, params);
//电子病例-保存-既往生活习惯-pastLivingHabits
export const ApiPastLivingHabitsSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/living-habits/add`, params);
//电子病例-保存-家族史-familyHistory
export const ApiFamilyHistorySave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/family-history/add`, params);
//电子病例-保存-一般用药史-generalMedicationHistory
export const ApiGeneralMedicationHistorySave = (
  params?: any
): Promise<Response> =>
  api.post(`/el-record/medical-history/general-medicine/add`, params);
//电子病例-保存-运动并发症-movementComplications
export const ApiMovementComplicationsSave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/motor-complications/add`, params);
//电子病例-保存-其它治疗史-otherTreatmentHistory
export const ApiOtherTreatmentHistorySave = (params?: any): Promise<Response> =>
  api.post(`/el-record/medical-history/other-treatment/add`, params);
//电子病例-病史-详情页
export const ApiPastMedicalHistoryDetails = (
  recordId: number
): Promise<Response> => api.get(`/el-record/his-disease/${recordId}/`);
export const ApiPastMedicalHistoryDetails2 = (
  recordId: number
): Promise<Response> => api.get(`/el-record/his-disease-two/${recordId}/`);
export const ApiPastMedicalHistoryDetails3 = (
  recordId: number
): Promise<Response> => api.get(`/el-record/his-disease-three/${recordId}/`);
export const ApiDeleteMedicine = (recordId: number): Promise<Response> =>
  api.post(`/case-history/medicine/delete/${recordId}/`);
export const ApiAddMedicine = (params?: any): Promise<Response> =>
  api.post(`/case-history/medicine/add`, params);

//电子病例-基础信息
export const ApiBaseinfomationDetails = (userId: number): Promise<Response> =>
  api.get(`/el-record/base-info/${userId}/`);

//嘉医工作站一期接口--结束

// 获取当前登陆的医生信息
// export const getCurrentDoctorInfo = (params?: any): Promise<Response> =>
//   api.post(`doctor/getCurrentDoctorInfo`, params);

// 查询问诊订单
// export const inquiryList = (params?: any): Promise<Response> =>
//   api.post(`inquiry/list`, params);

// 查询程控订单
export const appointmentList = (params?: any): Promise<Response> =>
  api.post(`appointment/list`, params);

// 查询电子病历库-添加患者
export const doctorsPatientAdd = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/add`, params);

// 电子病历库-查询患者
export const doctorsPatientList = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/list`, params);

//电子病历库-医生添加标签
export const doctortflagadd = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/flag/add`, params);

//电子病历库-医生编辑标签
export const doctortflagedit = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/flag/edit`, params);

// 电子病历库-医生查询标签
export const doctortflagList = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/flag/list`, params);

// 电子病历库-医生删除标签
export const doctortflagdelete = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/flag/delete`, params);

// 电子病历库-导出病历模板

export const doctortexport = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/export`, params);

//电子病历库-导入病历
export const doctortimport = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/import`, params);

// 电子病历库-医生查询默认标签
export const doctortflagDefault = (params?: any): Promise<Response> =>
  api.post(`doctorsPatient/flag/listDefault`, params);

//电子病历库-患者详情-查询可用标签
// export const doctortflagable = (params?: any): Promise<Response> =>
//   api.post(`doctorsPatient/flag/listAvailable`, params);

//电子病历库-添加患者标签
// export const patientflagadd = (params?: any): Promise<Response> =>
//   api.post(`/doctorsPatient/patient/flag/add`, params);

//电子病历库-医生编辑标签
// export const patientflagedit = (params?: any): Promise<Response> =>
//   api.post(`doctorsPatient/flag/edit`, params);

// 电子病历库-查询患者标签
// export const patientflagList = (params?: any): Promise<Response> =>
//   api.post(`/doctorsPatient/patient/flag/list`, params);

// 电子病历库-删除患者标签
// export const patientflagdelete = (params?: any): Promise<Response> =>
//   api.post(`/doctorsPatient/patient/flag/delete`, params);

//在线诊室-我的患者
export const myfollowerList = (params?: any): Promise<Response> =>
  api.post(`/doctor/follower/list`, params);
//在线诊室-患者详情
export const patientdetail = (params?: any): Promise<Response> =>
  api.post(`/doctor/patient/detail`, params);
//查询账务明细
// export const billingDetail = (params?: any): Promise<Response> =>
//   api.post(`/operation/billing/list`, params);
//运营管理-查询结算记录
// export const checkedList = (params?: any): Promise<Response> =>
//   api.post(`/operation/settlement/list`, params);
//在线诊室-收入明细
// export const checkedetail = (params?: any): Promise<Response> =>
//   api.post(`/doctor/billing/list`, params);
//嘉医工作站-首页统计信息
export const homeCensus = (params?: any): Promise<Response> =>
  api.post(`/doctor/homeStat`, params);

//common/url/trans
export const transferUrl = (params?: any): Promise<Response> =>
  api.post(`common/url/trans`, params, {
    // baseURL: process.env.VUE_APP_BASE_API_JOB,
  });

export const getCurrentDoctorInfo = (params?: any): Promise<Response> =>
  api.get(`el-record/base-info/20775`, params);
// el-record/base-info/20775

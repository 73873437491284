import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./assets/css/index.scss";
import "./apis";
import "./router/permission";
import "./plugins/print/printin.js";
import "default-passive-events";

import "animate.css";

//待清理
import VueBus from "vue-bus";
Vue.use(VueBus);

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import IconFont from "./components/IconFont.vue";
import "./assets/icon/iconfont.css";
Vue.component("icon-font", IconFont);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import Vuex from "vuex";
import { AppState } from "./modules/app";
import { UserState } from "./modules/user";
import { PermissionState } from "./modules/permission";
import getters from "./getters";
Vue.use(Vuex);
export interface RootState {
  app: AppState;
  user: UserState;
  permission: PermissionState;
}

export default new Vuex.Store<RootState>({
  getters,
});

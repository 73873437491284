import { User } from "@/types";
import api from "../request";
import { Response } from "@/types";
const keyBaseURL = "http://pinslife-res.joinpins.com";
console.log("environment====== ", process.env.NODE_ENV);
export const ApiPostVerifyCode = (): Promise<Response> =>
  api.post(`/verifyCode`);
// process.env.NODE_ENV !== "production" ? "" : keyBaseURL

// 若需要自动构建则baseURL的值需要改为keyBaseURL;
// 若本地调试则baseURL为""就行;
export const ApiGetPublicKey = (): Promise<any> =>
  // api.get(`/key/public/hospitalMange2020`, {
  //   baseURL: keyBaseURL,
  //   withCredentials: false,
  // });
  api.post(`/key/public`, {
    baseURL: keyBaseURL,
    withCredentials: false,
  });

// export const ApiGetUserList = (params?: any): Promise<Response> =>
//   api.post(`account/user/list`, params);

// export const ApiAddUser = (params: User): Promise<Response> =>
//   api.post(`account/user/add`, params);

// export const ApiDeleteUser = (id: number): Promise<Response> =>
//   api.post(`account/user/delete`, { id: id });

// export const ApiEditUser = (params: User): Promise<Response> =>
//   api.post(`account/user/edit`, params);

//添加登录设备
export const ApiAddLoginDevice = (params: any): Promise<Response> =>
  api.post(`v1/common/device/sign`, params, {
    baseURL: process.env.VUE_APP_SPARE_API,
  });
//退出登录设备
export const ApiSignOutDevice = (params: any): Promise<Response> =>
  api.post(`v1/common/device/exit`, params, {
    baseURL: process.env.VUE_APP_SPARE_API,
  });

import api from "../request";
import { Response } from "@/types";

// 影像资料列表
export const ApiGetVideoList = (param: any): Promise<Response> =>
  api.post(`/el-record/image/list`, param);

// 影像资料-嘉医工作站影像资料上传
export const ApiUpVideoList = (param: any): Promise<Response> =>
  api.post(`/el-record/image/add`, param);

// 影像资料-嘉医工作站影像资料删除
export const ApiDelVideoList = (param: any): Promise<Response> =>
  api.post(`/el-record/image/delete`, param);

// 获取图片文件流
export const ApiInstallImg = (params: any): Promise<Response> =>
  api.get("/appActivity/downloadQrCode", {
    params,
    responseType: "blob",
  });
// 获取图片文件流
export const ApiAddNote = (params: any): Promise<Response> =>
  api.post("/el-record/update/doctor/note", params);

import api from "../request";
import { Response } from "@/types";

// 查看就诊记录
export const getRecordInfo = (param: string): Promise<Response> =>
  api.get("el-record/medical-history/" + param);
// 病史卡片;
export const getTemcode = (userId: number): Promise<Response> =>
  api.post(`/case-history/temcode/get/${userId}`, {});

export const addTemcode = (param: object): Promise<Response> =>
  api.post(`/case-history/temcode/add`, param);
// 联系人列表;
export const getFamliyList = (userId: any): Promise<Response> =>
  api.post(`/case-history/family/list/${userId}`, {});

export const delFamilyContant = (familyId: number): Promise<Response> =>
  api.post(`/case-history/family/delete/${familyId}`, {});

export const addFamily = (param: object): Promise<Response> =>
  api.post(`/case-history/family/add`, param);

// 新增病历
// export const saveMedicalRecord = (param: object): Promise<Response> =>
//   api.post("/el-record/medical-history/add", { ...param });

// 病历详情
export const getMedicalRecordDetail = (param: number): Promise<Response> =>
  api.get("/el-record/medical-history/get/" + param);

// 主诉编辑
export const editChiefComplaint = (param: object): Promise<Response> =>
  api.post("/el-record/medical-history/medical-trmtchief/add", { ...param });

// 起病情况编辑
export const editStartMedical = (param: object): Promise<Response> =>
  api.post("/el-record/medical-history/medical-status/add", { ...param });

// 进展顺序编辑
export const editSequenceProgress = (param: object): Promise<Response> =>
  api.post("/el-record/medical-history/medical-order/add", { ...param });

// 进展顺序编辑
export const editSequenceProgress2 = (param: object): Promise<Response> =>
  api.post("/case-history/medical-history-one/add", { ...param });

// 主要症状编辑
export const editMianMedical = (param: object): Promise<Response> =>
  api.post("/el-record/medical-history/medical-cardinalSymptom/add", {
    ...param,
  });

// 其他运动症状编辑
export const editOtherMedical = (param: object): Promise<Response> =>
  api.post("/el-record/medical-history/medical-otherSport/add", { ...param });

// 症状诊断编辑
// export const editSymptomDiagnos = (param: object): Promise<Response> =>
//   api.post("/el-record/medical-history/medical-chatidiag/add", param);

// =====================================^.^

export const clinicAllData = (chiefId: number): Promise<Response> =>
  api.post(`/clinic/all-data/${chiefId}`, {});

export const delMedicalRecordDetial = (chiefId: number): Promise<Response> =>
  api.post(`/clinic/delete/${chiefId}`, {});

export const delMedicalHistory = (userId: number): Promise<Response> =>
  api.post(`/case-history/delete/${userId}`, {});

export const cureExerAdd = (param: object): Promise<Response> =>
  api.post("/clinic/cure-exer/add", param);

export const cureAcupAdd = (param: object): Promise<Response> =>
  api.post("/clinic/cure-acup/add", param);

export const cureOtherAdd = (param: object): Promise<Response> =>
  api.post("/clinic/cure-other/add", param);

export const cureMedicineAdd = (param: object): Promise<Response> =>
  api.post("/clinic/cure-medicine/add", param);

export const clinicImageAdd = (param: object): Promise<Response> =>
  api.post("/clinic/image/add", param);

export const clinicMedicineList = (
  trmtchief_id: number,
  param: object
): Promise<Response> =>
  api.post(`/clinic/medicine/list/${trmtchief_id}`, param);

export const clinicMedicineDel = (
  id: number,
  param: object
): Promise<Response> => api.post(`/clinic/medicine/delete/${id}`, param);

export const clinicMedicineAdd = (param: object): Promise<Response> =>
  api.post("/clinic/medicine/add", param);

export const clinicChatAdd = (param: object): Promise<Response> =>
  api.post("/clinic/chatitg/add", param);

export const clinicChaticereAdd = (param: object): Promise<Response> =>
  api.post("/clinic/chaticere/add", param);
// 心电图检查
export const clinicChatielecAdd = (param: object): Promise<Response> =>
  api.post("/clinic/chatielec/add", param);

export const clinicChatigeneAdd = (param: object): Promise<Response> =>
  api.post("/clinic/chatigene/add", param);

export const clinicImageDel = (id: number, param: object): Promise<Response> =>
  api.post(`/clinic/image/delete/${id}`, param);

export const clinicImageList = (
  trmtchief_id: number,
  param: object
): Promise<Response> => api.post(`/clinic/image/list/${trmtchief_id}`, param);

export const clinicChatinoAdd = (param: object): Promise<Response> =>
  api.post("/clinic/chatino/add", param);

export const clinicCureMedicineDel = (
  id: number,
  param: object
): Promise<Response> => api.post(`/clinic/cure-medicine/delete/${id}`, param);

export const clinicCureMedicineList = (
  trmtchief_id: number,
  param: object
): Promise<Response> =>
  api.post(`/clinic/cure-medicine/list/${trmtchief_id}`, param);

export const clinicCureOtherDel = (
  id: number,
  param: object
): Promise<Response> => api.post(`/clinic/cure-other/delete/${id}`, param);

export const clinicCureOtherList = (
  trmtchief_id: number,
  param: object
): Promise<Response> =>
  api.post(`/clinic/cure-other/list/${trmtchief_id}`, param);

export const clinicCureAcupDel = (
  id: number,
  param: object
): Promise<Response> => api.post(`/clinic/cure-acup/delete/${id}`, param);

export const clinicCureAcupList = (
  trmtchief_id: number,
  param: object
): Promise<Response> =>
  api.post(`/clinic/cure-acup/list/${trmtchief_id}`, param);

export const clinicCureExerDel = (
  id: number,
  param: object
): Promise<Response> => api.post(`/clinic/cure-exer/delete/${id}`, param);

export const clinicCureExerList = (
  trmtchief_id: number,
  param: object
): Promise<Response> =>
  api.post(`/clinic/cure-exer/list/${trmtchief_id}`, param);

export const medicalArchived = (
  trmtchief_id: number,
  userId: string
): Promise<Response> =>
  api.post(`/el-record/medical-history/archive/${trmtchief_id}/${userId}`, {});

export const medicalLog = (userId: number): Promise<Response> =>
  api.get(`/el-record/medical-history/logs/${userId}`);

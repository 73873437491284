import api from "../request";
import { Response } from "@/types";

// 下拉框-常量查询;
/** 性别  17
    疾病名称  611
    患者来源 607，635
    来源渠道 634
    证件类型 ---未找到
    受教育程度（学历）  603
    知情人与患者关系  610 **/

// export const ApiGetConstantList = (params?: any): Promise<Response> =>
//   api.post(`constant/list`, params);
// /patient-record/findByPage
// constant/list

// 查询全部
// function allList(params: any) {
//   return api.post("patient-record/findByPage", {
//     ...params,
//   });
// }
// 电子病例-患者病例;
// scale/userDetailinfo/list
// export const ApiGetPatientList = (params?: any): Promise<Response> =>
//   api.post(`patient-record/findByLike`, params);

// 电子病例-患者病例-新增;
// export const ApiAddPatientList = (params?: any): Promise<Response> =>
//   api.post(`scale/userDetailinfo/add`, params);

// 电子病例-患者病例-查看;
export const ApiGetPatientDetail = (params?: any): Promise<Response> =>
  api.post(`scale/userDetailinfo/detailInfo`, params);

// 获取量表分类;
export const ApiGetCategoryList = (params?: any): Promise<Response> =>
  api.post(`scale/appScaleCategory/list`, params);

// 获取医生量表列表;
export const ApiGetDoctorScaleList = (params?: any): Promise<Response> =>
  api.post(`scale/appScaleCategoryDetail/list`, params);

// 获取量表记录;
export const ApiGetPatientInfoRecord = (params?: any): Promise<Response> =>
  api.post(`/scale/appScalePatientInfoRecord/list`, params);

// 量表评估 详情;
export const ApiGetScaleDetail = (params?: any): Promise<Response> =>
  api.post(`scale/appScalePatientInfoRecord/detailInfo`, params);

// 获取量表题目;
export const ApiGetQuestionList = (params?: any): Promise<Response> =>
  api.post(`scale/appQuestion/list`, params);

// 新增量表;
export const ApiAddScaleRecord = (params?: any): Promise<Response> =>
  api.post(`scale/appScalePatientInfoRecord/add`, params);

//  量表添加-查询所有药品
export const ApiGetMedicineList = (params: any): Promise<Response> =>
  api.post("/scale/medicine/list", params);

// 新增病例-判断是否存在身份证号
// export const ApiGetIdCardBeing = (params: any): Promise<Response> =>
//   api.post("/scale/validate/idcardnum", params);
// export { allList };

































import { Component, Prop, Vue } from "vue-property-decorator";
import MenuItemDetail from "./MenuItemDetail.vue";

@Component({
  name: "MenuItemDetail",
  components: {
    MenuItemDetail,
  },
})
export default class MenuItem extends Vue {
  @Prop() item!: any;
  @Prop() isCollapse!: boolean;
  get hasChildren() {
    return this.item.child && this.item.child.length > 0;
  }

  handleMenuClick(item: any) {
    if (item.resource_url) {
      this.$emit("select", item);
    }
  }
}

import { AppModule } from "@/store/modules/app";

const breadcrumb = {
  push: (
    vm: any,
    path: string,
    name: string,
    pageParams: any,
    isClear: boolean
  ) => {
    const currentRoutePath = vm.$router.currentRoute.path;
    const pageConfig = {
      path: path,
      query: pageParams,
    };
    if (currentRoutePath !== path) {
      if (isClear) (AppModule as any).clearBreadcrumb();
      AppModule.addBreadcrumb({
        path: path,
        name: name,
        query: pageParams,
      });
      vm.$router.push(pageConfig);
    }
  },
  jump: (vm: any, path: string, pageParams: any, index: number) => {
    const currentRoutePath = vm.$router.currentRoute.path;
    const pageConfig = {
      path: path,
      query: pageParams,
    };
    if (currentRoutePath !== path) {
      AppModule.modifyBreadcrumb({
        index: index,
      });
      vm.$router.push(pageConfig);
    }
  },
  back: (vm: any) => {
    (AppModule as any).backBreadcrumb();
    const breadcrumb = AppModule.breadcrumb;
    const lastRouter = breadcrumb[breadcrumb.length - 1];
    vm.$router.push({
      path: lastRouter.path,
      query: lastRouter.query,
    });
  },
};

export { breadcrumb };

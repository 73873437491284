



















































import { Component, Vue, Watch } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { ApiPostLogout, ApiSignOutDevice } from "@/apis";
import * as storage from "@/utils";
import * as isLiu from "@/utils/judgeEnv";
import { breadcrumb } from "@/utils/breadcrumb";
@Component
export default class NavBar extends Vue {
  userName = "";
  imgUrl = "";
  firstRoutes: Array<any> = [];
  othersRoutes: Array<any> = [];
  numRoute: Array<any> = [];
  created() {
    this.userName = localStorage.getItem("userName") as any;
    if (localStorage.getItem("headIcon")) {
      this.imgUrl = localStorage.getItem("headIcon") as any;
    }
  }
  get breadcrumb() {
    return this.$store.state.app.breadcrumb;
  }
  @Watch("$route", {
    immediate: true,
  })
  handler(to: any, from: any) {
    const breadcrumbList = this.$store.state.app.breadcrumb;
    if (!breadcrumbList[breadcrumbList.length - 1]) return;
    const upperLevelBreadcrumb = breadcrumbList[breadcrumbList.length - 1].path;
    const isBrowserDefaultBack = to.fullPath.indexOf(upperLevelBreadcrumb);
    if (isBrowserDefaultBack === -1) {
      this.goBack();
    }
  }
  goBack() {
    breadcrumb.back(this);
  }
  handleSelect(path: any, name: any, query: any, index: number) {
    breadcrumb.jump(this, path, query, index);
  }
  //修改密码
  resetPassword() {
    this.$router.push("/reset/password");
  }
  async logout() {
    await ApiPostLogout();
    const userInfoId = JSON.parse(
      localStorage.getItem("doctorLoginInfo") as string
    ).id;
    const param = {
      user_id: userInfoId,
      device_module: 5,
    };
    ApiSignOutDevice(param).then((res) => {
      if (res.success) {
        res;
      }
    });
    storage.set("auth", null);
    if (isLiu.judgeEnv()) {
      this.$router.push("/login_liu");
    } else {
      this.$router.push("/login");
    }
  }
}

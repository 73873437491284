export const parseTime = function <T>(time: any, cFormat: string) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
};

export const addZero = function (num: string) {
  if (parseInt(num, 0) < 10) {
    num = "0" + num;
  }
  return num;
};

export const formatYMD = function (time: any) {
  if (!time) return "-";
  const date = new Date(time);
  const formatObj = {
    y: date.getFullYear(),
    m: (date.getMonth() + 1).toString(),
    d: date.getDate().toString(),
  };

  return formatObj.y + "-" + addZero(formatObj.m) + "-" + addZero(formatObj.d);
};

// 根据身份证号计算年龄;
export const getAgeFromIDCard = function (idCard: any) {
  const birthYear = parseInt(idCard.substring(6, 10));
  const now = new Date();
  const currentYear = now.getFullYear();

  const age = currentYear - birthYear;

  return age;
};
// 根据年月日计算;
export const getAgeFromIDCard_bak = function (idCard: any) {
  const birthYear = parseInt(idCard.substring(6, 10));
  const now = new Date();
  const currentYear = now.getFullYear();

  let age = currentYear - birthYear;

  const birthMonth = parseInt(idCard.substring(10, 12));
  const birthDay = parseInt(idCard.substring(12, 14));

  const currentMonth = now.getMonth() + 1;
  const currentDay = now.getDate();

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age--;
  }

  return age;
};

export const get = function <T>(key: string): T {
  const result: any = localStorage.getItem(key);
  try {
    return JSON.parse(result);
  } catch (e) {
    return result;
  }
};

export const set = function <T>(key: string, value: T): void {
  let result: string | T = value;
  if (typeof value === "object") {
    result = JSON.stringify(value);
  }
  localStorage.setItem(key, result as string);
};

import api from "../request";
import { Response } from "@/types";

// 添加最新用到的接口：
export const ApiGetOssAuth = (id: number, time: number): Promise<Response> =>
  api.get(`v1/pins-oss/object/uploading/auth/${id}/${time}`);

export const ApiObjectRecordsSave = (params?: any): Promise<Response> =>
  api.post(`v1/pins-oss/object/records/save`, params);

export const ApiObjectRecordsDelete = (params?: any): Promise<Response> =>
  api.post(`v1/pins-oss/object/delete`, params);

export const ApiOssViewFileAuth = (params?: any): Promise<Response> =>
  api.post(`v1/pins-oss/object/sign-url-batch`, params);

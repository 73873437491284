import api from "../request";
import { Response } from "@/types";
//标签-列表
interface ApiLabelListParam {
  cur_page?: string | number;
  page_size?: string | number;
}
export const ApiLabelList = (params?: ApiLabelListParam): Promise<Response> =>
  api.post(`/el-record/label/list`, params);
//标签-新增
interface ApiLabelAddParam {
  name: string;
  color: number;
  description: string;
}
export const ApiLabelAdd = (params?: ApiLabelAddParam): Promise<Response> =>
  api.post(`/el-record/label/add`, params);
//标签-编辑
interface ApiLabelEditParam {
  id: string;
  name: string;
  color: number;
  description: string;
}
export const ApiLabelEdit = (params?: ApiLabelEditParam): Promise<Response> =>
  api.put(`/el-record/label/edit`, params);
//标签-删除
interface ApiLabelDeleteParam {
  id: string;
}
export const ApiLabelDelete = (
  params: ApiLabelDeleteParam
): Promise<Response> => api.delete(`/el-record/label/delete/${params.id}`);
//问诊订单-列表
interface ApiConsultationOrder {
  start_createdate: number | string;
  end_createdate: number | string;
  module: number | string;
  patient_name: string;
  status: number | string;
  cur_page?: number | string;
  page_size?: number | string;
}
export const ApiConsultationOrderList = (params?: any): Promise<Response> =>
  api.post(`/el-record/appOrder/list`, params);
//病例列表-保存标签
interface ApiCaseLabel {
  user_id: number | string;
  tag: [any];
}
export const ApiCaseLabelSave = (params?: ApiCaseLabel): Promise<Response> =>
  api.post(`/patient-record/saveLable`, params);

//嘉医工作站一期接口--结束

//查询
// function allLabel(params: any) {
//   return api.get("el-record/all-label/" + params);
// }
// 分页
// function allLabels(params: any) {
//   return api.post("el-record/labelByPage", params);
// }

//添加
// function addLabel(params: any) {
//   return api.post("el-record/label/add", [params]);
// }

// 删除
// function deLabels(params: any) {
//   return api.delete("el-record/label/delete/" + params);
// }

// 删除 已经在使用过的
// function deLabelss(params: any) {
//   return api.delete("el-record/label/deleteTrue/" + params);
// }

// 编辑
// function putLabels(params: any) {
//   return api.put("el-record/label/edit", { ...params });
//   return;
// }

// 病人的标签操作
//   添加
// function userAddTag(params: any) {
//   return api.post("patient-record/saveFlag", {
//     ...params,
//   });
// }
//   删除
// function userDelTag(params: any) {
//   return api.post("patient-record/deleteFlag", {
//     ...params,
//   });
// }
// export {
//   allLabel,
//   addLabel,
//   deLabels,
//   putLabels,
//   allLabels,
//   deLabelss,
//   userAddTag,
//   userDelTag,
// };

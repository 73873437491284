import router from "@/router";
import * as storage from "@/utils";
import * as isLiu from "@/utils/judgeEnv";
const newFav = require("../assets/img/liu_favicon.png");

const whiteList = [
  "/login",
  "/login_liu",
  "/auth-redirect",
  "/direct_link",
  "/directLink",
]; // no redirect whitelist

// 暂不使用，登录页就是index
router.beforeEach(async (to, from, next) => {
  // start progress bar
  // NProgress.start()
  console.log(isLiu.judgeEnv(), "=== router");
  if (isLiu.judgeEnv()) {
    document.title = "中国进行性核上性麻痹协作平台";
    const faviconLink = document.querySelector(
      "link[rel='shortcut icon']"
    ) as any;
    faviconLink.href = newFav;
  }
  // set page title
  // document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  // TODO token 获取随后再弄
  console.log(to.path);
  const hasToken = storage.get("auth"); //localStore.getToken(); //CurrentUser.token; //store.getters.currentUser
  if (hasToken) {
    if (to.path === "/login" || to.path === "/login_liu") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      // NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      // TODO 角色也随后再弄
      const hasRoles = true; //store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next();
      } else {
        // try {
        //   // get user info
        //   // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
        //   const { roles } = await store.dispatch('user/getInfo')
        //
        //   // generate accessible routes map based on roles
        //   const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
        //
        //   // dynamically add accessible routes
        //   router.addRoutes(accessRoutes)
        //
        //   // hack method to ensure that addRoutes is complete
        //   // set the replace: true, so the navigation will not leave a history record
        //   next({ ...to, replace: true })
        // } catch (error) {
        //   // remove token and go to login page to re-login
        //   await store.dispatch('user/resetToken')
        //   Message.error(error || 'Has Error')
        //   next(`/login?redirect=${to.path}`)
        //   NProgress.done()
        // }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      if (isLiu.judgeEnv()) {
        next(`/login_liu?redirect=${to.path}`);
      } else {
        next(`/login?redirect=${to.path}`);
      }
      // NProgress.done()
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  // NProgress.done()
});

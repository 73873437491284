import api from "../request";
import { Response } from "@/types";

//程控历史列表
export const ApiGetControlHistoryList = (params: {
  user_name: number | string;
  date_start: number | string;
  date_end: number | string;
  cur_page: number | string;
  page_size: number | string;
}): Promise<Response> => api.post(`/scale/telepro/list`, params);
//程控历史导出
export const ApiExportControlHistory = (params: {
  user_name: number | string;
  date_start: number | string;
  date_end: number | string;
}): Promise<Response> =>
  api.post(`/scale/telepro/list/export`, params, {
    responseType: "blob",
  });
export const ApiExportControlHistoryFirst = (params: {
  user_name: number | string;
  date_start: number | string;
  date_end: number | string;
}): Promise<Response> => api.post(`/scale/telepro/list/export`, params);
// 患者病历库-程控历史导出

export const ApiGetExportControlHistory = (params: any): Promise<Response> =>
  api.post(
    `/el-record/telepro/export
`,
    params,
    {
      responseType: "blob",
    }
  );
export const ApiGetExportControlHistoryFirst = (
  params: any
): Promise<Response> =>
  api.post(
    `/el-record/telepro/export
`,
    params
  );
//阻抗历史列表
export const ApiGetImpedanceHistoryList = (params: {
  user_name: number | string;
  date_start: number | string;
  date_end: number | string;
  cur_page: number | string;
  page_size: number | string;
}): Promise<Response> => api.post(`/scale/stimtest/list`, params);
//阻抗历史导出
export const ApiExportImpedanceHistory = (params: {
  user_name: number | string;
  date_start: number | string;
  date_end: number | string;
}): Promise<Response> =>
  api.post(`/scale/stimtest/list/export`, params, {
    responseType: "blob",
  });
export const ApiExportImpedanceHistoryFirst = (params: {
  user_name: number | string;
  date_start: number | string;
  date_end: number | string;
}): Promise<Response> => api.post(`/scale/stimtest/list/export`, params);

// 患者病历-程控历史
// 程控历史列表
export const ApiGetPatientControlHistoryList = (
  params: any
): Promise<Response> => api.post(`/el-record/telepro/list`, params);
//程控历史导出
// export const ApiExportPatientControlHistory = (
//   params: any
// ): Promise<Response> =>
//   api.post(`/el-record/telepro/export`, params, {
//     responseType: "blob",
//   });
// 自增程控历史列表
export const ApiGetMyPatientControlHistoryList = (
  params: any
): Promise<Response> => api.post(`/el-record/self-telepro/list`, params);
// 添加自增程控历史
export const ApiGetAddMyPatientControlHistoryList = (
  params: any
): Promise<Response> => api.post(`/el-record/self-telepro/add`, params);
// 删除自增程控历史
export const ApiGetDelMyPatientControlHistoryList = (
  params: any
): Promise<Response> => api.post(`/el-record/self-telepro/delete`, params);

//程控历史导出
// export const ApiMyExportControlHistory = (params: any): Promise<Response> =>
//   api.post(`el-record/telepro/export`, params, {
//     responseType: "blob",
//   });

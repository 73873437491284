import api from "../request";
import { Response } from "@/types";

export const ApiBroadcastPatientExcel = (params: any): Promise<Response> =>
  api.post(`/doctor/broadcast/patient/download/excel`, params, {
    responseType: "blob",
  });

export const ApiBroadcastQuesExcel = (params: any): Promise<Response> =>
  api.post(`/doctor/broadcast/download/word/${params.id}`, params, {
    responseType: "blob",
  });

export const ApiBroadcastPatientList = (params?: any): Promise<Response> =>
  api.post(`/doctor/broadcast/patient/pager/list`, params);

export const ApiBroadcastQuestionList = (params?: any): Promise<Response> =>
  api.post(`/doctor/broadcast/question/pager/list`, params);

export const ApiBroadcastQuesSelect = (params?: any): Promise<Response> =>
  api.post(`/doctor/broadcast/question/select/${params.id}`, params);

export const ApiBroadcastPatientUpdate = (params?: any): Promise<Response> =>
  api.post(`/doctor/broadcast/patient/update`, params);

export const ApiBroadcastPatientSelect = (params?: any): Promise<Response> =>
  api.post(`/doctor/broadcast/patient/select`, params);

export const ApiBroadcastPatientExcelBefore = (
  params: any
): Promise<Response> =>
  api.post(`/doctor/broadcast/patient/download/excel`, params);

export const ApiMedicineLists = (params: any): Promise<Response> =>
  api.post(`/doctor/medicine/getList`, params);

export const ApiMedicineDel = (params: any): Promise<Response> =>
  api.post(`/doctor/medication/adjustment/delete/name`, params);

export const ApiMedicineSave = (params: any): Promise<Response> =>
  api.post(`/doctor/medication/adjustment/save/name`, params);

export const ApiMedicineSaveAdj = (params: any): Promise<Response> =>
  api.post(`/doctor/medication/adjustment/save`, params);

export const ApiAdjSelect = (params: any): Promise<Response> =>
  api.post(`/doctor/medication/adjustment/select/${params.id}`, params);

export const ApiBroadcastQuestionDelete = (params: any): Promise<Response> =>
  api.post(`/doctor/broadcast/question/delete/${params}`, params);

export const ApiBroadcastQuestionSave = (params?: any): Promise<Response> =>
  api.post(`/doctor/broadcast/question/save`, params);

// 新增  病历

import api from "../request";

// 基础
// function basicsMessage(params: any) {
//   return api.post("el-record/medical-history/basic-information/add", {
//     ...params,
//   });
// }

//主诉
function trmtchief(params: any) {
  return api.post("el-record/medical-history/medical-trmtchief/add", {
    ...params,
  });
}

// 现病史 起病情况
// function trmtprst(params: any) {
//   return api.post("el-record/medical-history/medical-status/add", {
//     ...params,
//   });
// }

// 现病史 进展顺序
// function lu_limb(params: any) {
//   return api.post("el-record/medical-history/medical-order/add", {
//     ...params,
//   });
// }

// 现病史 进展顺序 temp tow
function addMedicalHistoryTwo(params: any) {
  return api.post("case-history/medical-history-two/add", {
    ...params,
  });
}

// 现病史 进展顺序 temp3 three
function addMedicalHistoryThree(params: any) {
  return api.post("case-history/medical-history-three/add", {
    ...params,
  });
}
// 现病史 主要症状
// function cardinalSymptom(params: any) {
//   return api.post("el-record/medical-history/medical-cardinalSymptom/add", {
//     ...params,
//   });
// }
// 现病史 其他运动症状
// function otherSport(params: any) {
//   return api.post("el-record/medical-history/medical-otherSport/add", {
//     ...params,
//   });
// }

//症状诊断
// function chatidiag(params: any) {
//   return api.post("el-record/medical-history/medical-chatidiag/add", params);
// }
// 删除单个 症状诊断
// function delChatidiag(params: any) {
//   return api.delete(
//     "el-record/medical-history/medical-chatidiag/delete/" + params
//   );
// }
//既往疾病史
// function jibing(params: any) {
//   return api.post("el-record/medical-history/jiwangjibing/add", params);
// }
// 删除既往疾病史
// function delJibing(params: any) {
//   return api.delete("el-record/medical-history/jiwangjibing/delete/" + params);
// }
//既往 环境接触史
// function environmental(params: any) {
//   return api.post("el-record/medical-history/environmental-contact/add", {
//     ...params,
//   });
// }

// 既往生活习惯

// function living(params: any) {
//   return api.post("el-record/medical-history/living-habits/add", { ...params });
// }
//家族史
// function family(params: any) {
//   return api.post("el-record/medical-history/family-history/add", params);
// }
// 删除家族史
// function delFamily(params: any) {
//   return api.delete(
//     "/el-record/medical-history/family-history/delete/" + params
//   );
// }

// 用药史-一般用药史
// function generalMedicine(params: any) {
//   return api.post("el-record/medical-history/general-medicine/add", params);
// }
// 删除单个 一般用药史
// function delGeneralMedicine(params: any) {
//   return api.delete(
//     "el-record/medical-history/general-medicine/delete/" + params
//   );
// }
// 用药史  运动并发症
// function complications(params: any) {
//   return api.post("el-record/medical-history/motor-complications/add", {
//     ...params,
//   });
// }
//其他治疗史
// function otherTreatment(params: any) {
//   return api.post("el-record/medical-history/other-treatment/add", params);
// }
// 删除单个 其他治疗史
// function delOtherTreatment(params: any) {
//   return api.delete(
//     "el-record/medical-history/other-treatment/delete/" + params
//   );
// }
// 过敏史
function allergies(params: any) {
  return api.post("el-record/allergies/edit", {
    ...params,
  });
}

// 流行病学史;
function ApiEidemiologicalHistorySave(params: any) {
  return api.post("el-record/epidemic-disease/add", {
    ...params,
  });
}

export {
  // basicsMessage,
  trmtchief,
  // trmtprst,
  // lu_limb,
  // otherSport,
  // cardinalSymptom,
  // chatidiag,
  // jibing,
  // environmental,
  // family,
  // complications,
  // living,
  // generalMedicine,
  // otherTreatment,
  allergies,
  // delJibing,
  // delFamily,
  // delGeneralMedicine,
  // delOtherTreatment,
  // delChatidiag,
  addMedicalHistoryTwo,
  addMedicalHistoryThree,
  ApiEidemiologicalHistorySave,
};

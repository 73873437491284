export * from "./Ajax/user";
export * from "./Ajax/workStand";
export * from "./Ajax/activity";
export * from "./Ajax/manage";
export * from "./Ajax/organization";
export * from "./Ajax/permission";
export * from "./Ajax/scale";
export * from "./Ajax/controlHistory";

export * from "./Ajax/addCase";
export * from "./Ajax/labels";
export * from "./Ajax/myUser";
export * from "./Ajax/medicalHistory";
export * from "./Ajax/particulars";
export * from "./Ajax/visitRecord";
export * from "./Ajax/scaleAssessment";

export * from "./Ajax/OperationRecord";
export * from "./Ajax/uploadFile";
export * from "./Ajax/videoData";

export * from "./Ajax/livePatient";
export * from "./Ajax/doctorContent";

import axios from "axios";

// 手机验证
export const phoneCheck = (rule: any, value: any, callback: any) => {
  const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  if (reg.test(value)) {
    callback();
  } else {
    callback("请输入正确的手机号");
  }
};

// 校验身份证号
export const checkID = (str: any) => {
  // return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str)
  return /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/.test(
    str
  );
};

// 校验身份证
export const checkIdCard = (idCard: any, name: any, cb: any) => {
  const appcode = "713a4e8b6ff24c3e977a0b0643f99dc4";
  const urlSend = "https://bcard3and4.market.alicloudapi.com/IDCard";
  axios
    .get(urlSend, {
      params: { idCard: idCard, name: name },
      headers: {
        Authorization: "APPCODE  " + appcode,
      },
    })
    .then((res) => {
      if (res.data.status === "01") {
        cb(res.data.status);
      } else {
        cb("姓名与身份证号不匹配，请重新填写");
      }
    });
};
//  以上方法调用;
//  utils.checkIdCard("621121199511110063","孔莉",(res:any)=> {
//   console.log(res)
// });

import Vue from "vue";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Element);
Element.Dialog.props.closeOnClickModal.default = false;
// MessageBox
Element.MessageBox.setDefaults({
  closeOnClickModal: false,
});

import api from "../request";
import { Response } from "@/types";

// 获取手术记录列表
export const ApiGetOperationRecordList = (param: any): Promise<Response> =>
  api.post(`/el-record/operation-record/list`, param);

// 添加手术记录
export const ApiGetAddOperationRecord = (param: any): Promise<Response> =>
  api.post(`/el-record/operation-record/add`, param);

// 删除手术记录
export const ApiGetDelOperationRecord = (param: any): Promise<Response> =>
  api.post(`/el-record/operation-record/delete`, param);

export const ApiConsentAdd = (param: any): Promise<Response> =>
  api.post(`/pd-operation/consent/add`, param);

export const ApiStimAdd = (param: any): Promise<Response> =>
  api.post(`/pd-operation/stim/add`, param);

export const ApiVideoAdd = (param: any): Promise<Response> =>
  api.post(`/pd-operation/video/add`, param);

export const ApiImageAdd = (param: any): Promise<Response> =>
  api.post(`/pd-operation/image/add`, param);

export const ApiConsentDelete = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/consent/delete/${id}`, param);

export const ApiConsentList = (userId: any): Promise<Response> =>
  api.post(`/pd-operation/consent/list/${userId}`, {});

export const ApiAuthorizationAdd = (param: any): Promise<Response> =>
  api.post(`/pd-operation/authorization/add`, param);

export const ApiAuthorizationDel = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/authorization/delete/${id}`, param);

export const ApiAuthorizationList = (
  param: any,
  userId: any
): Promise<Response> =>
  api.post(`/pd-operation/authorization/list/${userId}`, param);

export const ApiVideoDel = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/video/delete/${id}`, param);

export const ApiVideoList = (param: any, userId: any): Promise<Response> =>
  api.post(`/pd-operation/video/list/${userId}`, param);

export const ApiStimDel = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/stim/delete/${id}`, param);

export const ApiStimList = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/stim/list/${id}`, param);

export const ApiImageDel = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/image/delete/${id}`, param);

export const ApiImageList = (param: any, userId: any): Promise<Response> =>
  api.post(`/pd-operation/image/list/${userId}`, param);

export const ApiMicroelecAdd = (param: any): Promise<Response> =>
  api.post(`/pd-operation/microelec/add`, param);

export const ApiDiscussionDel = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/discussion/delete/${id}`, param);

export const ApiDiscussionAdd = (param: any): Promise<Response> =>
  api.post(`/pd-operation/discussion/add`, param);

export const ApiMicroelecDel = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/microelec/delete/${id}`, param);

export const ApiMicroelecList = (param: any, id: any): Promise<Response> =>
  api.post(`/api3/pd-operation/microelec/list/${id}`, param);

export const ApiDiscussionList = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/discussion/list/${id}`, param);

export const ApiOperationTotalList = (param: any, id: any): Promise<Response> =>
  api.post(`/pd-operation/all/list/${id}`, param);

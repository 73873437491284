






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class IconFont extends Vue {
  @Prop() private name!: string;

  get href() {
    return `#icon-${this.name}`;
  }
}

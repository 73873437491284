export const judgeEnv = function () {
  const env = process.env.NODE_ENV;

  // if (window.location.host == "localhost:8080") {
  //   return false;
  // }
  // if (window.location.host == "192.168.40.27:8080") {
  //   return true;
  // }
  if (env == "development") {
    if (window.location.host == "192.168.1.78:83") {
      return false;
    } else if (window.location.host == "192.168.1.78:124") {
      return true;
    }
  } else if (env == "test") {
    if (window.location.host == "192.168.1.78:84") {
      return false;
    } else if (window.location.host == "192.168.1.78:125") {
      return true;
    }
  } else if (env == "production") {
    if (window.location.host == "doctorserv.pinsmedical.com") {
      return false;
    } else if (window.location.host == "cheeloo-psp.pinsmedical.com") {
      return true;
    }
  }
  return false;
};

console.log(judgeEnv(), "===========judgeEnv");















































import { Component, Vue, Watch } from "vue-property-decorator";
import MenuItemDetail from "./MenuItemDetail.vue";
import { FunctionGroup } from "@/types";
import { AppModule } from "@/store/modules/app";
import * as isLiu from "@/utils/judgeEnv";
import { breadcrumb } from "@/utils/breadcrumb";
import { ApiPostMenuTree } from "@/apis";

@Component({
  components: {
    MenuItemDetail,
  },
})
export default class Sidebar extends Vue {
  get isCollapse() {
    return this.$store.getters.sidebar.opened;
  }

  get functionGroupsArray() {
    return this.$store.getters.sidebar.functionGroupsArray;
  }
  //默认打开的菜单索引
  defaultOpenMenuName = [];
  //本地变量
  isPutAway = false;
  toggleSideBar() {
    if (this.isPutAway == false) {
      this.isPutAway = !this.isPutAway;
    } else {
      setTimeout(() => {
        this.isPutAway = !this.isPutAway;
      }, 300);
    }

    if (!this.isCollapse) {
      (document as any).querySelector(".menu__header-title")
        ? ((document as any).querySelector(
            ".menu__header-title"
          ).style.display = "none")
        : null;

      (document as any).querySelector(".footer")
        ? (document as any).querySelector(".footer").classList.add("collapsed")
        : null;
      (document as any).querySelector(".footer_buttons")
        ? (document as any)
            .querySelector(".footer_buttons")
            .classList.add("collapsed")
        : null;
    } else {
      (document as any).querySelector(".menu__header-title-liu")
        ? ((document as any).querySelector(
            ".menu__header-title-liu"
          ).style.display = "none")
        : null;

      (document as any).querySelector(".footer")
        ? (document as any)
            .querySelector(".footer")
            .classList.remove("collapsed")
        : null;
      (document as any).querySelector(".footer_buttons")
        ? (document as any)
            .querySelector(".footer_buttons")
            .classList.remove("collapsed")
        : null;
    }
    AppModule.ToggleSideBar();
  }

  currentRoutes = "/home";
  isLiuLogo = false;
  shouldHideTitle = false;
  created() {
    // (this as any).$bus.$on("nvrs", (val: any) => {
    //   this.currentRoutes = val.substring(1);
    // });
    this.isLiuLogo = isLiu.judgeEnv();
    this.getUserInfo();
  }
  mounted() {
    this.defaultOpenMenuName = this.functionGroupsArray.map((item: any) => {
      return item.name;
    });
    //初始化默认选中菜单
    const defaultMenuItem = sessionStorage.getItem("menu_default_item");
    if (defaultMenuItem) {
      this.currentRoutes = defaultMenuItem;
    } else {
      this.currentRoutes = "/home";
    }

    if (!this.isCollapse) {
      (document as any).querySelector(".footer")
        ? (document as any)
            .querySelector(".footer")
            .classList.remove("collapsed")
        : null;
      (document as any).querySelector(".footer_buttons")
        ? (document as any)
            .querySelector(".footer_buttons")
            .classList.remove("collapsed")
        : null;
    } else {
      (document as any).querySelector(".footer")
        ? (document as any).querySelector(".footer").classList.add("collapsed")
        : null;
      (document as any).querySelector(".footer_buttons")
        ? (document as any)
            .querySelector(".footer_buttons")
            .classList.add("collapsed")
        : null;
    }
  }
  async getUserInfo() {
    try {
      const params = {
        types: ["G", "M", "C"],
        parent_id: "",
        system_code: [2],
      };
      const result = await ApiPostMenuTree(params);
      if (result.success) {
        result.data.unshift({
          full_path: "/",
          resource_url: "/",
          id: -1,
          is_collect: 0,
          logo_url: require("@/assets/img/icon-home.png"),
          menu_name: "首页",
          parent_id: 0,
          type: "G",
        });
        AppModule.ToggleFunctionGroupArray(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleSelect(item: any) {
    if (!item.resource_url) return;
    const name = (item.full_path + item.menu_name).replace("/", "");
    breadcrumb.push(this, item.resource_url, name, null, true);
    sessionStorage.setItem("menu_default_item", name);
  }

  getLogoUrl(group: FunctionGroup) {
    let result = "";
    // const pathArr = this.$router.currentRoute.path.split("/");
    // if (group.url && pathArr[1] === group.url.slice(1, group.url.length)) {
    //   result = group.logo2_url;
    // } else {
    result = group.logo_url;
    // }
    return result;
  }
}

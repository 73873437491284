import api from "../request";
import { Response } from "@/types";

//获取医生所属量表名称下拉框
export const ApiGetScaleNameSelectDoctor = (): Promise<Response> =>
  api.post(`/scale/appScaleCategoryDetail/doctor/list`);
//获取患者所属量表名称下拉框
export const ApiGetScaleNameSelectPatient = (): Promise<Response> =>
  api.post(`/scale/appScaleCategoryDetail/patient/list`);
//获取医生评估结果列表
export const ApiGetScaleListDoctor = (params?: any): Promise<Response> =>
  api.post(`/scale/appScalePatientInfoRecord/doctor/list`, params);
//获取患者自评结果列表
export const ApiGetScaleListPatient = (params?: any): Promise<Response> =>
  api.post(`/scale/appScalePatientInfoRecord/patient/list`, params);
//病例详情-量表评估tab页-医生评估量表结果列表
export const ApiGetTabScaleListDoctor = (params?: any): Promise<Response> =>
  api.post(`/scale/detail/doctor/list`, params);
//病例详情-量表评估tab页-患者自评量表结果列表
export const ApiGetTabScaleListPatient = (params?: any): Promise<Response> =>
  api.post(`/scale/detail/patient/list`, params);
//新增量表-患者姓名下拉框数据获取
export const ApiGetPatientNameList = (params?: any): Promise<Response> =>
  api.post(`/scale/userDetailinfo/name/list`, params);
//新增量表-获取可选药物列表
export const ApiGetDrugsListToChoose = (params?: any): Promise<Response> =>
  api.post(`/scale/medicine/list`, params);
//新增量表-查询量表所属问卷内容
export const ApiGetScaleQuestion = (params?: any): Promise<Response> =>
  api.post(`/scale/appQuestion/list`, params);
//新增量表-提交
export const ApiSubmitScale = (params?: any): Promise<Response> =>
  api.post(`/scale/appScalePatientInfoRecord/add`, params);
//量表评估-详情查看
export const ApiGetScaleDetailInfo = (params?: any): Promise<Response> =>
  api.post(`/scale/appScalePatientInfoRecord/detailInfo`, params);
// 患者信息回显
export const ApiGetPatientInfo = (params?: any): Promise<Response> =>
  api.post(`/scale/detail`, params);

export const ApiScaleDownload = (params?: any): Promise<Response> =>
  api.post(`/scale/download-word`, {});

export const ApiScaleDownloadDetail = (params?: any): Promise<Response> =>
  api.post(`/api2/patientScale/downLoad`, params, {
    baseURL: process.env.VUE_APP_Upload_Oss_File,
  });

import api from "../request";
import { IUserProp, IUserParams, Response } from "@/types";

export const ApiPostDoLogin = (
  username: string,
  password: string,
  verifyCode: string,
  jsessionid: string
): Promise<Response> =>
  api.post(`/doLogin/3`, {
    username: username,
    password: password,
    verify_code: verifyCode,
    system_type: 2,
    jsessionid: jsessionid,
  });

export const ApiPostDoLoginOld = (
  username: string,
  password: string,
  verifyCode: string,
  jsessionid: string
): Promise<Response> =>
  api.post(`/doLogin`, {
    username: username,
    password: password,
    verify_code: verifyCode,
    system_type: 2,
    jsessionid: jsessionid,
  });
export const ApiPostDoLoginNoAuth = (
  username: string,
  password: string,
  jsessionid: string
): Promise<Response> =>
  api.post(`/doLogin`, {
    username: username,
    password: password,
    system_type: 2,
    jsessionid: jsessionid,
  });
export const ApiPostDoReset = (
  oldPassword: string,
  newPassword: string
): Promise<Response> =>
  api.post(`/account/user/password/modify`, {
    old_password: oldPassword,
    password: newPassword,
  });
export const ApiPostLogout = (): Promise<Response> => api.post(`/logout`, {});

export const ApiPostUserRegister = (user: IUserParams): Promise<IUserProp> =>
  api.post(`/user/register`, { user }, { params: user });

export const ApiPostUserInfo = (type: number): Promise<Response> =>
  api.post(`/account/user/get/${type}`);

export const ApiPostMenuTree = (params: any): Promise<Response> =>
  api.post(`/menu/index/tree`, params);

// export const ApiGetUserPhone = (
//   username: string,
//   vcode: string
// ): Promise<Response> =>
//   api.post(
//     "/api3/user/sendSms/byDoctorUsername",
//     {
//       username: username,
//       vcode: vcode,
//     },
//     { baseURL: process.env.VUE_APP_Upload_Oss_File }
//   );

// export const ApiGetCodeMessage = (
//   telephone: string,
//   codeType: number
// ): Promise<Response> =>
//   api.post(
//     "/api2/user/getMessageCode",
//     {
//       telephone: telephone,
//       codeType: codeType,
//     },
//     { baseURL: process.env.VUE_APP_Upload_Oss_File }
//   );

// export const ApiCheckCode = (
//   username: string,
//   vcode: string
// ): Promise<Response> =>
//   api.post(
//     "/api3/user/sms/check",
//     { username: username, vcode: vcode },
//     {
//       baseURL: process.env.VUE_APP_Upload_Oss_File,
//     }
//   );

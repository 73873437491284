import store from "@/store";
import { RouteConfig } from "vue-router";
import * as storage from "@/utils/index";
import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import { FunctionGroup } from "@/types";
import { ApiPostMenuTree } from "@/apis";
export interface AppState {
  sidebar: {
    opened: boolean;
    functionGroupsArray: Array<FunctionGroup>;
  };
  breadcrumb: Array<any>;
  currentUser: any;
}

@Module({ dynamic: true, store, name: "app" })
class App extends VuexModule implements AppState {
  sidebar = {
    opened: storage.get("sidebarStatus") === 1 ? true : false,
    functionGroupsArray: storage.get("funcGroupArray") as Array<FunctionGroup>,
  };
  currentUser = {};
  breadcrumb: Array<any> = storage.get("breadcrumb")
    ? storage.get("breadcrumb")
    : [{ path: "/home", name: "首页" }];

  @Action({ commit: "TOGGLE_SIDEBAR" })
  ToggleSideBar() {
    return true;
  }
  @Action({ commit: "TOGGLE_FUNCTION_GROUP_ARRAY" })
  async ToggleRefreshFuncGroupArray() {
    const params = {
      types: ["G", "M", "C"],
      parent_id: "",
      system_code: [2],
    };
    const res = await ApiPostMenuTree(params);
    res.data.unshift({
      full_path: "/",
      resource_url: "/",
      id: -1,
      is_collect: 0,
      logo_url: require("@/assets/img/icon-home.png"),
      menu_name: "首页",
      parent_id: 0,
      type: "G",
    });
    return res.data;
  }
  @Action({ commit: "TOGGLE_FUNCTION_GROUP_ARRAY" })
  ToggleFunctionGroupArray(funcGroupArray: Array<FunctionGroup>) {
    return funcGroupArray;
  }
  @Mutation
  private TOGGLE_SIDEBAR() {
    this.sidebar.opened = !this.sidebar.opened;
    if (this.sidebar.opened) {
      storage.set("sidebarStatus", 1);
    } else {
      storage.set("sidebarStatus", 0);
    }
  }
  @Mutation
  private TOGGLE_FUNCTION_GROUP_ARRAY(funcGroupArray: Array<FunctionGroup>) {
    this.sidebar.functionGroupsArray = funcGroupArray;
    storage.set("funcGroupArray", funcGroupArray);
  }
  //给面包屑使用的mution
  @Mutation
  addBreadcrumb(params: any) {
    this.breadcrumb.push(params);
    storage.set("breadcrumb", this.breadcrumb);
  }
  @Mutation
  clearBreadcrumb() {
    this.breadcrumb = [];
    storage.set("breadcrumb", this.breadcrumb);
  }
  @Mutation
  modifyBreadcrumb(params: any) {
    // console.log(this.breadcrumb);
    // console.log(params.index);
    //index为点击的面包屑这一项在面包屑列表的索引
    //但是通常能点击的面包屑只有一项，所以index常为0
    this.breadcrumb.splice(
      params.index + 1,
      this.breadcrumb.length - params.index - 1
    );
    storage.set("breadcrumb", this.breadcrumb);
  }
  @Mutation
  backBreadcrumb() {
    this.breadcrumb.pop();
    storage.set("breadcrumb", this.breadcrumb);
  }
}

export const AppModule = getModule(App);
